<template>
  <section class="position-fixed w-100 bg-white">
    <div class="content-fluid">
      <div class="row mx-0 align-items-center border-bottom">
        <div class="col-auto pt-3 text-center text-lg-left mx-auto ml-xl-5" onclick="window.scrollTo(0, 0)">
          <router-link to="/">
            <img src="@/assets/RevendaX.png" alt="logo_revendaX">
          </router-link>
          <h1 class="d-none m-0">Revenda X</h1>
          <h2 class="subtitle m-0 pb-1">Sua plataforma <span class="text-orange">white label</span></h2>
        </div>
        <div class="d-none d-lg-block col mr-5">
          <div class="row mx-0 align-items-center justify-content-end">
            <div v-for="(item, i) in list" :key="`nav-${i}`" v-show="item.show.includes($route.path)"
              class="col-auto px-0 mr-3">
              <router-link :to="item.link" class="decoration-none">
                {{ item.name }} </router-link>
            </div>
            <a href="http://doc.pdvx.com.br/" target="_blank" rel="noopener noreferrer">API</a>
            <div class="col-auto mx-0 ml-3 px-0">
              <router-link to="/#contact" class="btn btn-outline-success">Fale com o Especialista</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    data() {
      return {
        list: [{
          name: 'Sobre a Parceria',
          link: '#skills',
          show: ['/']
        }, {
          name: 'Funcionalidades',
          link: '#features',
          show: ['/']
        }, {
          name: 'Portfolio',
          link: 'portfolio',
          show: ['/']
        }, {
          name: 'Início',
          link: '/',
          show: ['/portfolio']
        }]
      }
    }
  }
</script>

<style scoped>
  .position-fixed {
    z-index: 10;
  }

  .col {
    max-width: 900px;
  }

  img {
    width: 100%;
    height: auto;
    max-width: 200px;
    object-fit: cover;
  }

  .subtitle {
    font-size: 20px;
    font-weight: bold;
  }

  .seta::before {
    /* Setas */
    content: '';
    position: absolute;
    width: 138px;
    height: 93px;
    left: 434.96px;
    top: 596px;

    transform: matrix(-0.91, 0.42, 0.42, 0.91, 0, 0);
  }
</style>