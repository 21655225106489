import axios from 'axios';

const state = {}

const mutations = {}

const actions = {
  sendEmail: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.post(`/contato`, payload)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    })
    return promise;
  }
}

export default {
  state,
  mutations,
  actions
}