<template>
  <div id="app">
    <Start />
    <!-- Google Tag Manager (noscript) -->
    <iframe v-if="status" src="https://www.googletagmanager.com/ns.html?id=GTM-MBPDWJ2" height="0" width="0"
      style="display:none;visibility:hidden"></iframe>
    <!-- End Google Tag Manager (noscript) -->
  </div>
</template>

<script>
  import "./store/metrics.js";
  import Start from '@/views/Home/Index.vue';
  export default {
    components: {
      Start,
    },
    computed: {
      status() {
        return (process.env.NODE_ENV == 'production')
      }
    }
  }
</script>

<style>
  * {
    scroll-behavior: smooth;
    /* -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
  }

  #app {
    /* font-family: Avenir, Helvetica, Arial, sans-serif; */
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* text-align: center; */
    color: #2c3e50;
    /* margin-top: 60px; */
  }

  .fixed {
    margin-top: -82px;
    padding-top: 82px;
  }

  .btn-orange {
    color: white !important;
    background-color: #ff7f00 !important;
    transition: all 0.2s;
  }

  .btn-orange:hover {
    color: #db6e00 !important;
    border-color: #db6e00 !important;
    background-color: white !important;
  }

  .bg-blue {
    background-color: #3C8DBC;
  }

  .rounded-btn {
    border-radius: 1.3em !important;
  }

  .text-blue {
    color: #3C8DBC;
  }

  .text-orange {
    color: #ff7f00;
  }

  .title {
    font-size: 60px;
    font-weight: bold;
  }

  .title2 {
    font-size: 40px;
    font-weight: bold;
  }

  .subtitle {
    font-size: 23px;
    font-weight: 400;
  }

  @media screen and (max-width: 992px) {
    .title {
      font-size: 30px !important;
    }

    .title2 {
      font-size: 20px;
      font-weight: bold;
    }

    .subtitle {
      font-size: 16px !important;
    }
  }
</style>