import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import routes from './routes'
import axios from 'axios'
import App from './App.vue'

import * as modules from './store/index.js'
import common from './store/common.js';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import Toasted from 'vue-toasted';

require('@/assets/Poppins.css');
require('@/assets/fontawesome/css/all.min.css');
require('@/assets/fontawesome/css/fontawesome.min.css');

Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Toasted);

axios.defaults.baseURL = 'https://mbd.ddns.net:3333';

const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior: (to, from, next) => {
    return (to.hash) ? { selector: to.hash } : { x: 0, y: 0 }
  }
});

const store = new Vuex.Store({
  modules,
  state: {}
});

Vue.prototype.axios = axios;
Vue.prototype.store = store;

Vue.mixin(common);

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
