const routes = [{
  path: '*',
  name: 'all',
  redirect: '/'
}, {
  path: '/',
  components: {
    Homepage: () => import('./views/Home/Homepage.vue'),
    Benefits: () => import('./views/Home/Benefits.vue'),
    Skills: () => import('./views/Home/Skills.vue'),
    Steps: () => import('./views/Home/Steps.vue'),
    Features: () => import('./views/Home/Features.vue'),
    Contact: () => import('./views/Home/Contact.vue'),
  },
}, {
  path: '/portfolio',
  components: {
    Index: () => import('./views/portfolio/index.vue'),
    Products: () => import('./views/portfolio/products.vue'),
    Segments: () => import('./views/portfolio/segments.vue'),
    Features: () => import('./views/portfolio/features.vue'),
    Steps: () => import('./views/portfolio/steps.vue'),
    Plans: () => import('./views/portfolio/plans.vue'),
    CaseUse: () => import('./views/portfolio/case-use.vue'),
    Statement: () => import('./views/portfolio/statements.vue'),
  },
}]

export default routes;